<template>
  <v-tooltip top v-if="tooltip">
    <template v-slot:activator="{ on }">
      <span v-on="on">
        {{ value }}
      </span>
    </template>
    {{ tooltip }}
  </v-tooltip>
  <span v-else>{{ displayText }}</span>
</template>
<script>
export default {
  name: 'StaticText',
  props: {
    value: { default: null },
    placeholder: { type: String, default: '—' },
    tooltip: { type: String, default: null },
  },
  computed: {
    displayText() {
      return this.value ? this.value : this.placeholder;
    },
  },
};
</script>
