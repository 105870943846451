<template>
  <StaticText :value="display" :tooltip="tooltip" />
</template>
<script>
import StaticText from 'common/components/StaticText.vue';
export default {
  name: 'LookupValue',
  components: { StaticText },
  props: {
    long: { type: Boolean, default: false },
    short: { type: Boolean, default: false },
    value: { type: Object, default: null },
  },
  computed: {
    display() {
      if (!this.value) return null;
      if (this.short) {
        return this.value.code;
      } else if (this.long) {
        return `${this.value.description} (${this.value.code})`;
      } else {
        return this.value.description;
      }
    },
    tooltip() {
      return this.value && this.short ? this.value.description : null;
    },
  },
};
</script>
