<template>
  <div v-if="enabled">
    <v-card class="mb-4" v-if="!edit" :loading="loading">
      <v-list subheader>
        <v-subheader>Naturwissenschaften plus</v-subheader>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-subtitle>1. Priorität</v-list-item-subtitle>
            <v-list-item-title>
              <LookupValue long :value="subjectA" />
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-subtitle>1. Priorität</v-list-item-subtitle>
            <v-list-item-title>
              <LookupValue long :value="subjectB" />
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-subtitle>2. Priorität</v-list-item-subtitle>
            <v-list-item-title>
              <LookupValue long :value="subjectC" />
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-card-actions
        ><v-spacer /><v-btn color="primary" text @click="edit = true"
          >Prioritäten ändern</v-btn
        ></v-card-actions
      >
    </v-card>
    <v-card class="mb-4" v-else :loading="loading">
      <v-list subheader>
        <v-subheader>Naturwissenschaften plus</v-subheader>
        <v-list-item>
          <LookupValueInput
            v-model="subjectA"
            :items="subjects"
            label="1. Priorität"
          />
        </v-list-item>
        <v-list-item>
          <LookupValueInput
            v-model="subjectB"
            :items="subjects"
            label="1. Priorität"
          />
        </v-list-item>
        <v-list-item>
          <LookupValueInput
            v-model="subjectC"
            :items="subjects"
            label="2. Priorität"
          />
        </v-list-item>
      </v-list>
      <v-card-actions>
        <v-btn text @click="cancel()">abbrechen</v-btn>
        <v-spacer /><v-btn
          color="primary"
          text
          :disabled="!subjectA || !subjectB || !subjectC || saving"
          @click="save"
          >Prioritäten speichern</v-btn
        ></v-card-actions
      >
    </v-card>
  </div>
</template>

<script>
import LookupValue from 'common/components/LookupValue.vue';
import LookupValueInput from 'common/components/LookupValueInput.vue';
export default {
  components: {
    LookupValue,
    LookupValueInput,
  },
  props: { id: { type: Number, default: 0 } },
  data() {
    return {
      edit: false,
      enabled: false,
      loading: false,
      saving: false,
      search: '',
      subjects: [],
      subjectA: null,
      subjectB: null,
      subjectC: null,
    };
  },
  computed: {
    forMe() {
      return this.id == 0;
    },
  },
  watch: {
    subjectA(value) {
      if (value && this.subjectB && value.id == this.subjectB.id) {
        this.subjectB = null;
      }
      if (value && this.subjectC && value.id == this.subjectC.id) {
        this.subjectC = null;
      }
    },
    subjectB(value) {
      if (value && this.subjectA && value.id == this.subjectA.id) {
        this.subjectA = null;
      }
      if (value && this.subjectC && value.id == this.subjectC.id) {
        this.subjectC = null;
      }
    },
    subjectC(value) {
      if (value && this.subjectA && value.id == this.subjectA.id) {
        this.subjectA = null;
      }
      if (value && this.subjectB && value.id == this.subjectB.id) {
        this.subjectB = null;
      }
    },
  },
  methods: {
    async fetchData() {
      this.loading = true;
      let data;
      if (this.id) {
        data = await this.apiList({
          resource: 'optional/scienceplus',
          query: 'student=' + this.id,
        });
      } else {
        data = await this.apiList({
          resource: 'optional/scienceplus',
        });
      }
      if (data.studentItems.length > 0) {
        this.subjectA = data.studentItems[0].sciencePlusChoice.subjectA;
        this.subjectB = data.studentItems[0].sciencePlusChoice.subjectB;
        this.subjectC = data.studentItems[0].sciencePlusChoice.subjectC;
        this.subjects = data.sciencePlusSubjects;
        this.enabled = true;
      }

      this.loading = false;
    },
    cancel() {
      this.edit = false;
      this.fetchData();
    },
    async save() {
      this.saving = true;
      const data = {
        subjectA: this.subjectA,
        subjectB: this.subjectB,
        subjectC: this.subjectC,

        student: this.id,
      };
      await this.apiPost({
        resource: 'optional/scienceplus',
        data: data,
      });
      this.saving = false;
      this.edit = false;
      this.fetchData();
    },
  },

  async created() {
    this.fetchData();
  },
};
</script>
