<template>
  <v-autocomplete
    :value="value"
    :disabled="!itemsComputed || itemsComputed.length < 1 || $attrs.disabled"
    :items="itemsComputed"
    :label="label"
    :item-text="$attrs.itemText ? $attrs.itemText : 'description'"
    item-value="id"
    @change="select"
    return-object
    v-bind="$attrs"
  >
    <template v-for="slot in parentSlots" #[slot]
      ><slot :name="slot"
    /></template>
  </v-autocomplete>
</template>
<script>
export default {
  name: "LookupValueInput",
  props: {
    value: { type: Object, default: null },
    label: { type: String, default: "Wert" },
    items: { type: Array, default: null },
    resource: { type: String, default: "" },
    query: { type: String, default: "" },
  },
  data() {
    return {
      itemsFromURL: null,
    };
  },
  computed: {
    parentSlots() {
      return Object.keys(this.$slots);
    },
    itemsComputed() {
      return this.items ? this.items : this.itemsFromURL;
    },
  },
  methods: {
    select($event) {
      this.$emit("input", $event);
    },
  },
  async created() {
    if (this.resource) {
      this.itemsFromURL = await this.apiList({
        resource: this.resource,
        query: this.query,
      });
    }
  },
};
</script>
